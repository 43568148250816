const emCodes = ['99201', '99202', '99203', '99204', '99205', '99211', '99212', '99213', '99214', '99215', '99217', '99218', '99219', '99220', '99221', '99222', '99223', '99224', '99225', '99226', '99231', '99232', '99233', '99234', '99235', '99236', '99238', '99239', '99241', '99242', '99243', '99244', '99245', '99251', '99252', '99253', '99254', '99255', '99281', '99282', '99283', '99284', '99285', '99288', '99289', '99290', '99291', '99292', '99293', '99294', '99295', '99296', '99298', '99299', '99300', '99304', '99305', '99306', '99307', '99308', '99309', '99310', '99315', '99316', '99318', '99324', '99325', '99326', '99327', '99328', '99334', '99335', '99336', '99337', '99339', '99340', '99341', '99342', '99343', '99344', '99345', '99347', '99348', '99349', '99350', '99354', '99355', '99356', '99357', '99358', '99359', '99360', '99361', '99362', '99363', '99364', '99366', '99367', '99368', '99371', '99372', '99373', '99374', '99375', '99377', '99378', '99379', '99380', '99381', '99382', '99383', '99384', '99385', '99386', '99387', '99391', '99392', '99393', '99394', '99395', '99396', '99397', '99401', '99402', '99403', '99404', '99406', '99407', '99408', '99409', '99411', '99412', '99415', '99416', '99417', '99418', '99420', '99421', '99422', '99423', '99429', '99431', '99432', '99433', '99435', '99436', '99439', '99440', '99441', '99442', '99443', '99444', '99446', '99447', '99448', '99449', '99450', '99451', '99452', '99453', '99454', '99455', '99456', '99457', '99458', '99460', '99461', '99462', '99463', '99464', '99465', '99466', '99467', '99468', '99469', '99471', '99472', '99473', '99474', '99475', '99476', '99477', '99478', '99479', '99480', '99481', '99482', '99483', '99484', '99485', '99486', '99487', '99488', '99489', '99490', '99491', '99492', '99493', '99494', '99495', '99496', '99497', '99498', '99499']

const specialtyMap = {
  '208D00000X': 'General Practice',
  '208600000X': 'Surgery',
  '207K00000X': 'Allergy & Immunology',
  '207Y00000X': 'Otolaryngology',
  '207L00000X': 'Anesthesiology',
  '207RC0000X': 'Internal Medicine, Cardiovascular Disease',
  '207N00000X': 'Dermatology',
  '207Q00000X': 'Family Practice',
  '208VP0014X': 'Pain Medicine, Interventional Pain Medicine',
  '207RG0100X': 'Internal Medicine, Gastroenterology',
  '207R00000X': 'Internal Medicine',
  '204D00000X': 'Neuromusculoskeletal Medicine & OMM',
  '2084N0400X': 'Psychiatry & Neurology, Neurology',
  '207T00000X': 'Neurological Surgery',
  '207V00000X': 'Obstetrics & Gynecology',
  '207W00000X': 'Ophthalmology',
  '1223S0112X': 'Oral & Maxillofacial Surgery',
  '207X00000X': 'Orthopedic Surgery',
  '207ZP0102X': 'Pathology-Anatomic Pathology & Clinical Pathology',
  '2086S0122X': 'Surgery, Plastic & Reconstructive',
  '208100000X': 'Physical Medicine & Rehabilitation',
  '2084P0800X': 'Psychiatry & Neurology-Psychiatry',
  '208C00000X': 'Colon & Rectal Surgery',
  '207RP1001X': 'Internal Medicine, Pulmonary Diseases',
  '2085R0202X': 'Radiology Group, Diagnostic Radiology',
  '367H00000X': 'Anesthesiologist Assistant',
  '208G00000X': 'Thoracic Surgery',
  '208800000X': 'Urology',
  '111N00000X': 'Chiropractor',
  '207U00000X': 'Nuclear Medicine',
  '208000000X': 'Pediatrics',
  '207RG0300X': 'Internal Medicine, Geriatric Medicine',
  '207RN0300X': 'Internal Medicine, Nephrology',
  '2086S0105X': 'Surgery, Surgery of the Hand',
  '152W00000X': 'Optometrist',
  '367A00000X': 'Midwife, Certified Nurse',
  '367500000X': 'Nurse Anesthetist, Certified Registered',
  '207RI0200X': 'Internal Medicine, Infectious Disease',
  '261QR0206X': 'Clinic/Center-Radiology, Mammography',
  '207RE0101X': 'Internal Medicine-Endocrinology, Diabetes & Metabolism',
  '293D00000X': 'Physiological Laboratory: (Independent Physiological Lab)',
  '213E00000X': 'Podiatrist',
  '261QA1903X': 'Clinic/Center, Ambulatory Surgical',
  '363L00000X': 'Nurse Practitioner',
  '335E00000X': 'Prosthetic/Orthotic Supplier',
  '332B00000X': 'Durable Medical Equipment & Medical Supplies',
  '222Z00000X': 'Orthotist',
  '224P00000X': 'Prosthetist',
  '225000000X': 'Orthotics/Prosthetics Fitter',
  '3416L0300X': 'Ambulance (Land)',
  '251K00000X': 'Public Health or Welfare',
  '251V00000X': 'Voluntary or Charitable',
  '103T00000X': 'Psychologist',
  '335V00000X': 'Portable X-ray Supplier',
  '231H00000X': 'Audiologist',
  '225100000X': 'Physical Therapist',
  '207RR0500X': 'Internal Medicine, Rheumatology',
  '225X00000X': 'Occupational Therapist',
  '103TC0700X': 'Psychologist/Clinical',
  '291U00000X': 'Clinical Medical Laboratory',
  '193200000X': 'Group',
  '133V00000X': 'Dietitian, Registered',
  '208VP0000X': 'Pain Medicine',
  '261QX0203X': 'Clinic/Center: Oncology, Radiation',
  '2086S0129X': 'Surgery, Vascular Surgery',
  '207RA0401X': 'Internal Medicine, Addiction Medicine',
  '1041C0700X': 'Social Worker, Clinical',
  '207RC0200X': 'Internal Medicine, Critical Care Medicine',
  '207RH0000X': 'Internal Medicine, Hematology',
  '207RH0003X': 'Internal Medicine, Hematology & Oncology',
  '2083P0901X': 'Preventive Medicine, Public Health & General Preventive Medicine',
  '204E00000X': 'Oral & Maxillofacial Surgery',
  '2084N0600X': 'Psychiatry & Neurology, Clinical Neurophysiology',
  '364S00000X': 'Clinical Nurse Specialist',
  '207RX0202X': 'Internal Medicine, Medical Oncology',
  '2086X0206X': 'Surgery, Surgical Oncology',
  '2085R0001X': 'Radiology, Radiation Oncology',
  '207P00000X': 'Emergency Medicine',
  '2085R0204X': 'Radiology Group, Vascular & Interventional Radiology',
  '156FX1800X': 'Technician/Technologist-Optician',
  '363A00000X': 'Physician Assistant',
  '207VX0201X': 'Obstetrics & Gynecology, Gynecologic Oncology',
  '282N00000X': 'General Acute Care Hospital',
  '314000000X': 'Skilled Nursing Facility',
  '313M00000X': 'Nursing Facility/Intermediate Care Facility',
  '251E00000X': 'Home Health',
  '333600000X': 'Pharmacy'
}

const processFile = text => {
  const lines = text.split('~').map(line => line.trim())
  const claims = []
  let currentClaim = null
  let subElementDelimiter = ':'

  lines.forEach((line, lineIndex) => {
    const segments = line.split('*')

    switch (segments[0].trim()) {
      case 'ISA':
        subElementDelimiter = line[line.length - 1]
        break
      case 'CLM':
        if (currentClaim) {
          claims.push(currentClaim)
        }
        currentClaim = {
          cptCodes: [],
          dxCodes: [],
          emLevel: null,
          claimId: segments[1],
          dateOfService: null,
          providerName: null,
          providerSpecialty: null,
          providerTaxonomy: null
        }
        break
      case 'NM1':
        if (segments[1] === '82') {
          const ln = segments[3]
          const fn = segments[4]
          let providerName
          if (ln && fn) {
            providerName = `${ln}, ${fn}`
          } else if (ln) {
            providerName = `${ln}, ${fn}`
          }
          currentClaim.providerName = providerName
        }
        break
      case 'DTP':
        if (segments[1] === '472' && currentClaim) {
          currentClaim.dateOfService = segments[3]
        }
        break
      case 'SV1':
        if (currentClaim) {
          // ~SV1*HC:99391:25*283*UN*1***1:2:3
          let code = segments[1]
          let units = null
          if (segments[1].startsWith(`HC${subElementDelimiter}`)) {
            code = code.slice(3)
          }
          if (segments[3].startsWith('UN')) {
            units = segments[4].trim()
          }
          // parse modifiers
          let mods = []
          if (code.includes(subElementDelimiter)) {
            const parsedCpt = code.split(subElementDelimiter)
            code = parsedCpt[0]
            mods = parsedCpt.slice(1, 5).filter(f => f)
          }
          if (code.length <= 5) {
            const currentSegmentAndNextSegment = line + '~' + lines[lineIndex + 1]
            currentClaim.cptCodes.push({ cpt: code, modifiers: mods, units: units, segment: currentSegmentAndNextSegment })
            const isEmCode = emCodes.some(x => x === code)
            if (isEmCode) {
              const level = code[4]
              const currentLevel = currentClaim.emLevel ? currentClaim.emLevel : 0
              currentClaim.emLevel = level > currentLevel && level < 6 ? level : currentLevel
            }
          }
        }
        break
      case 'HI':
        if (currentClaim) {
          segments.slice(1).forEach(code => {
            if (code.startsWith('BK') || code.startsWith('BF') || code.startsWith('ABK') || code.startsWith('ABF')) {
              currentClaim.dxCodes.push(code.split(subElementDelimiter)[1])
            }
          })
        }
        break
      case 'PRV':
        if (segments[1] === 'PE' && currentClaim) {
          const specialtyCode = segments[3].trim()
          currentClaim.providerSpecialty = specialtyMap[specialtyCode]
          currentClaim.providerTaxonomy = specialtyCode
        }
        break
    }
  })

  // Push the last claim if it exists
  if (currentClaim) {
    claims.push(currentClaim)
  }

  return claims
}

export default processFile
