<template>
  <div>
    <div>
      <el-row style="margin: 10px 0px 30px 0px;">
        <el-col :span="3">
          <el-button icon="el-icon-check" class="hiaButton" plain round size="mini" @click="runAcknowledgeNoChanges()">Acknowledge No Changes</el-button>
        </el-col>
        <el-col :span="18">
          <center class="stats">
            <el-badge v-for="stat in opListStatistics" :key="stat.key" class="item" :class="{ 'stats-loading': statsLoading }" :value="stat.count">
              <el-tag type="primary" size="small">{{ stat.key }}</el-tag>
            </el-badge>
          </center>
        </el-col>
        <el-col :span="3">
          <el-row :gutter="2">
            <el-col :span="24">
              <el-input id="globalSearch" type="text" size="mini" placeholder="filter all..." v-model="filterCriteria" v-on:keyup.native="runGlobalFilter($event)"> </el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <div style="margin-top: 10px; min-height: 200px;">
          <v-server-table ref="coderOutpatientListGrid" id="coderOutpatientListGrid" :columns="columns" :options="options" class="hiaTable" @loading="tableLoader = true" @loaded="tableLoader = false" v-loading="tableLoader" element-loading-spinner="atom-audit-loader">
            <template slot="patientNumber" slot-scope="props">
              <router-link :to="`/my-projects/outpatient/${props.row.ascTableId}`">{{ props.row.patientNumber }}</router-link>
            </template>
            <template slot="dateOfService" slot-scope="props">
              <div>
                {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
              </div>
            </template>
            <template slot="completeDate" slot-scope="props">
              <div>
                {{ props.row.completeDate ? formatDate(props.row.completeDate) : '' }}
              </div>
            </template>
            <template slot="reviewDate" slot-scope="props">
              <div>
                {{ props.row.reviewDate ? formatDate(props.row.reviewDate) : '' }}
              </div>
            </template>
            <template slot="level2ReasonForChange" slot-scope="props">
              <div>
                {{ props.row.reviewLevel !== 'Coding Review' ? props.row.level2ReasonForChange : '' }}
              </div>
            </template>
            <template slot="rebuttalDate" slot-scope="props">
              <div>
                {{ props.row.rebuttalDate ? formatDate(props.row.rebuttalDate) : '' }}
              </div>
            </template>
            <template slot="delete" slot-scope="props">
              <el-popover :popper-class="'delete_' + props.row.ascTableId" trigger="click" placement="left" width="190">
                <center>
                  <p>Delete this Chart?</p>
                  <!-- <el-button type="primary" size="mini" @click="deleteChart(props)">confirm</el-button> -->
                  <el-button-group>
                    <el-button class="hiaButton" plain round type="text" size="mini" @click="deleteChart(props, true)">Cancel</el-button>
                    <el-button class="hiaButton" plain round size="mini" @click="deleteChart(props)">Confirm</el-button>
                  </el-button-group>
                </center>
                <div slot="reference">
                  <!-- <button :disabled="tableLoader" size="mini" type="button" title="Delete Chart" class="deleteButton" style="float: right;"></button> -->
                  <div title="delete" style="text-align: center; cursor: pointer;">
                    <i class="el-icon-close delete-button"></i>
                  </div>
                </div>
              </el-popover>
            </template>
          </v-server-table>
        </div>
      </el-row>
    </div>
    <div id="dialogs">
      <coderInpatientWorksheetModal v-if="showWorksheetModal" :ascTableId="worksheetascTableId" :showWorksheetModal="showWorksheetModal" @close="handleModalClose"> </coderInpatientWorksheetModal>
      <MyProjectsOutpatientMassUpdate v-if="showMassUpdateDialog" @massupdate="handleMassUpdate" @close="showMassUpdateDialog = false" :showMassUpdateDialog="showMassUpdateDialog" :clients="dropdowns.clients.list" :selectedRecords="getSelectedRecords()" :selectedClients="[]"> </MyProjectsOutpatientMassUpdate>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'

  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'
  import debounce from 'debounce'
  import MyProjectsOutpatientMassUpdate from './MyProjectsOutpatientMassUpdate'

  export default {
    name: 'inpatientList',
    mixins: [dateHelper, hiaTable],
    data() {
      return {
        showMassUpdateDialog: false,
        statsLoading: false,
        vueTables2Query: {},
        loading: true,
        showWorksheetModal: false,
        worksheetascTableId: null,
        filterCriteria: '',
        tableLoader: false,
        isActivated: true,
        columns: ['patientNumber', 'patientType', 'mrNumber', 'consultantNumber', 'payorName', 'coder', 'reasonForChange', 'dateOfService', 'comments', 'reviewDate', 'status', 'auditResult', 'agreeWithCodes', 'responseNeeded', 'rebuttalDate', 'finalOutcome'],
        options: {
          // see the options API
          actionsPosition: 'right',
          requestFunction: data => {
            return this.requestData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            reviewLevel: []
          },
          sortable: ['patientNumber', 'patientType', 'mrNumber', 'consultantNumber', 'payorName', 'coder', 'reasonForChange', 'dateOfService', 'comments', 'reviewDate', 'status', 'auditResult', 'agreeWithCodes', 'responseNeeded', 'rebuttalDate', 'finalOutcome'],
          columnsClasses: {
            selected: 'selected',
            // excludeRecord: 'hideColumn',
            // ipreviewType: 'hideColumn',
            // subClientNumber: 'hideColumn',
            // originalReviewer: 'hideColumn',
            // originalReviewDate: 'hideColumn',
            // originalClientNumber: 'hideColumn',
            // reviewLevel: 'hideColumn',
            delete: 'delete'
          },
          headings: {
            selected: h => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'selectAllCheckboxOutpatientList'
                },
                on: {
                  click: e => {
                    this.selectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            },
            agreeWithCodes: 'Agree with Changes',
            auditResult: 'Audit Result',
            patientNumber: 'Patient Number',
            consultantNumber: 'Consultant Number',
            payorName: 'Payor',
            mrNumber: 'MR Number',
            coder: 'Coder',
            patientType: 'Pat Type',
            physicianName: 'Provider',
            dateOfService: 'Date of Service',
            reasonForChange: 'Reason F Change',
            comments: 'Comments',
            reviewDate: 'Review Date',
            responseNeeded: 'Response Needed',
            responseCount: 'Response Count',
            finalOutcome: 'Final Outcome',
            rebuttalDate: 'Response Date',
            delete: ''
          },
          perPage: 25,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('coder/', ['LOAD_CODER_OP_LIST_PAGINATED', 'RUN_OP_MASS_ACKNOWLEDGE_NO_CHANGES']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS', 'CONVERT_DROPDOWN_COLUMNFILTERARRAY']),
      ...mapMutations('outpatientReviews/', ['SET_OUTPATIENTLIST_SELECTED', 'CLEAR_OUTPATIENTLIST_SELECTED', 'SET_OUTPATIENTLIST_REFRESH_FUNC']),
      async requestData(data) {
        try {
          data.projectID = this.$route.params.id
          data.query.projectID = this.$route.params.id
          data.globalSearch = this.filterCriteria
          this.vueTables2Query = data
          this.statsLoading = true
          const resultArray = await this.LOAD_CODER_OP_LIST_PAGINATED(data)
          this.statsLoading = false
          return resultArray
        } catch (err) {
          console.log(err)
        }
      },
      getSelectedRecords() {
        const simpleArray = this.opListSelected.map(y => y.ascTableId)
        return [...new Set(simpleArray)]
      },
      async localSelectAll(checked) {
        const table = this.$refs.ipReviewListGrid.$el
        const checkboxes = table.querySelectorAll('input[type ="checkbox"]')

        if (checked) {
          this.tableLoader = true
          await this.GET_OUTPATIENT_LIST_ALLIDS(this.vueTables2Query)
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = true
          }
          this.tableLoader = false
          // this.$refs.ipReviewListGrid.refresh()
        } else {
          this.CLEAR_OUTPATIENTLIST_SELECTED()
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false
          }
          // this.$refs.ipReviewListGrid.refresh()
        }
      },
      runGlobalFilter: debounce(function() {
        this.$refs.coderOutpatientListGrid.getData()
      }, 400),
      async handleMassUpdate(massUpdateModel) {
        this.showMassUpdateDialog = false
        // this.localSelectAll(false) // clear all the selected checkboxes
        // this.$refs.ipReviewListGrid.getData()
      },
      runAcknowledgeNoChanges() {
        this.$confirm('Acknowledge No Changes for records without changes?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          const payload = {
            projectID: this.$route.params.id,
            outpatientGrouperId: 1,
            rebuttalDate: new Date().toISOString()
          }
          this.tableLoader = true
          this.RUN_OP_MASS_ACKNOWLEDGE_NO_CHANGES(payload).then(
            () => {
              this.$refs.coderOutpatientListGrid.getData()
              this.tableLoader = false
              this.$message({
                message: 'Acknowledge No Changes Successful',
                type: 'success'
              })
            },
            error => {
              console.log(error)
              this.tableLoader = false
              const message = error.response.data.message || 'Acknowledge No Changes Error'
              this.$message({
                message: message,
                type: 'error'
              })
            }
          )
        })
      }
    },
    computed: {
      ...mapState('outpatientReviews/', ['statistics', 'opListSelected', 'opListRecordValidations']),
      ...mapState('managedLists/projects/', ['projectPK', 'project']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('coder/', ['opListStatistics'])
    },
    created: function() {
      // this.defaultColumns = JSON.parse(JSON.stringify(this.sortableColumns))
      // this.loading = true
    },
    mounted: function() {
      if (this.$route.query.id) {
        this.openWorksheetModal({ ascTableId: this.$route.query.id })
      }
    },
    activated: function() {
      if (this.$refs.coderOutpatientListGrid && !this.isActivated) {
        this.$refs.coderOutpatientListGrid.getData()
      }
      this.isActivated = true
    },
    deactivated: function() {
      this.isActivated = false
      this.localInpatientReviews = []
      // clear any old validation messages, validation messages can be left around because the form validation gets triggered natively by
      // the browser when users hit the enter key in the form
      if (this.$refs.addForm) {
        this.$refs.addForm.clearValidate()
      }
      // this.localSelectAll(false)
      // this.CLEAR_OUTPATIENTLIST_SELECTED()
    },
    components: {
      MyProjectsOutpatientMassUpdate
    }
  }
</script>

<style scoped>
  .item {
    margin-top: 3px;
    margin-right: 35px;
  }

  .stats-loading {
    opacity: 0.5;
  }
</style>
