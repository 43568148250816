const mapping = {
  'General Practice': 'General Practice',
  Surgery: 'Cardiac Surgery',
  'Allergy & Immunology': 'Allergy/ Immunology',
  Otolaryngology: 'Otolaryngology',
  Anesthesiology: 'Anesthesiology',
  'Internal Medicine, Cardiovascular Disease': 'Advanced Heart Failure and Transplant Cardiology',
  Dermatology: 'Dermatology',
  'Family Practice': 'Family Practice',
  'Pain Medicine, Interventional Pain Medicine': 'Interventional Pain Management',
  'Internal Medicine, Gastroenterology': 'Gastroenterology',
  'Internal Medicine': 'Internal Medicine',
  'Neuromusculoskeletal Medicine & OMM': 'Neuromusculoskeletal Medicine & OMM',
  'Psychiatry & Neurology, Neurology': 'Neurology',
  'Neurological Surgery': 'Neurosurgery',
  'Obstetrics & Gynecology': 'Obstetrics & Gynecology',
  Ophthalmology: 'Ophthalmology',
  'Oral & Maxillofacial Surgery': 'Oral Surgery (Dentist only)',
  'Orthopedic Surgery': 'Orthopedic Surgery',
  'Pathology-Anatomic Pathology & Clinical Pathology': 'Pathology',
  'Surgery, Plastic & Reconstructive': 'Plastic and Reconstructive Surgery',
  'Physical Medicine & Rehabilitation': 'Physical Medicine and Rehabilitation',
  'Psychiatry & Neurology-Psychiatry': 'Psychiatry',
  'Colon & Rectal Surgery': 'Colorectal Surgery (Proctology)',
  'Internal Medicine, Pulmonary Diseases': 'Pulmonary Disease',
  'Radiology Group, Diagnostic Radiology': 'Diagnostic Radiology',
  'Thoracic Surgery': 'Thoracic Surgery',
  Urology: 'Urology',
  Chiropractor: 'Chiropractic',
  'Nuclear Medicine': 'Nuclear Medicine',
  Pediatrics: 'Pediatric Medicine',
  'Internal Medicine, Geriatric Medicine': 'Geriatric Medicine',
  'Internal Medicine, Nephrology': 'Nephrology',
  'Surgery, Surgery of the Hand': 'Hand Surgery',
  Optometrist: 'Optometry',
  'Midwife, Certified Nurse': 'Certified Nurse Midwife',
  'Nurse Anesthetist, Certified Registered': 'Certified Registered Nurse Anesthetist (CRNA)',
  'Internal Medicine, Infectious Disease': 'Infectious Disease',
  'Clinic/Center-Radiology, Mammography': 'Clinical Laboratory',
  'Internal Medicine-Endocrinology, Diabetes & Metabolism': 'Endocrinology',
  'Physiological Laboratory: (Independent Physiological Lab)': 'General Practice',
  Podiatrist: 'Podiatry',
  'Clinic/Center, Ambulatory Surgical': 'Clinic or Group Practice',
  'Nurse Practitioner': 'Nurse Practitioner',
  'Prosthetic/Orthotic Supplier': 'General Practice',
  'Durable Medical Equipment & Medical Supplies': 'General Practice',
  Orthotist: 'General Practice',
  Prosthetist: 'General Practice',
  'Orthotics/Prosthetics Fitter': 'General Practice',
  'Ambulance (Land)': 'General Practice',
  'Public Health or Welfare': 'Public Health or Welfare Agency',
  'Voluntary or Charitable': 'General Practice',
  Psychologist: 'Psychologist, Clinical',
  'Portable X-ray Supplier': 'General Practice',
  Audiologist: 'Audiologist',
  'Physical Therapist': 'Physical Therapist in Private Practice',
  'Internal Medicine, Rheumatology': 'Rheumatology',
  'Occupational Therapist': 'General Practice',
  'Psychologist/Clinical': 'Psychologist, Clinical',
  'Clinical Medical Laboratory': 'Clinical Laboratory',
  Group: 'Clinic or Group Practice',
  'Dietitian, Registered': 'Registered Dietitian or Nutrition Professional',
  'Pain Medicine': 'Pain Management',
  'Clinic/Center: Oncology, Radiation': 'Radiation Therapy Center',
  'Surgery, Vascular Surgery': 'Vascular Surgery',
  'Internal Medicine, Addiction Medicine': 'Addiction Medicine',
  'Social Worker, Clinical': 'Licensed Clinical Social Worker',
  'Internal Medicine, Critical Care Medicine': 'Critical Care (Intensivists)',
  'Internal Medicine, Hematology': 'Hematology',
  'Internal Medicine, Hematology & Oncology': 'Hematology-Oncology',
  'Preventive Medicine, Public Health & General Preventive Medicine': 'Preventive Medicine',
  'Psychiatry & Neurology, Clinical Neurophysiology': 'General Practice',
  'Clinical Nurse Specialist': 'Certified Clinical Nurse Specialist',
  'Internal Medicine, Medical Oncology': 'Medical Oncology',
  'Surgery, Surgical Oncology': 'Surgical Oncology',
  'Radiology, Radiation Oncology': 'Radiation Oncology',
  'Emergency Medicine': 'Emergency Medicine',
  'Radiology Group, Vascular & Interventional Radiology': 'Interventional Radiology',
  'Technician/Technologist-Optician': 'General Practice',
  'Physician Assistant': 'Physician Assistant',
  'Obstetrics & Gynecology, Gynecologic Oncology': 'Gynecological Oncology',
  'General Acute Care Hospital': 'General Practice',
  'Skilled Nursing Facility': 'General Practice',
  'Nursing Facility/Intermediate Care Facility': 'General Practice',
  'Home Health': 'General Practice',
  Pharmacy: 'General Practice'
}

const medicare = {
  newPatient: {
    level1: 8863,
    level2: 996358,
    level3: 8690518,
    level4: 11266524,
    level5: 2415468
  },
  establishedPatient: {
    level1: 3295153,
    level2: 8923374,
    level3: 73449684,
    level4: 97219471,
    level5: 10340344
  }
}

const bySpecialty = {
  'Addiction Medicine': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 211,
      level4: 1128,
      level5: 265
    },
    establishedPatient: {
      level1: 528,
      level2: 875,
      level3: 12120,
      level4: 23009,
      level5: 1698
    }
  },
  'Adult Congenital Heart Disease': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 96,
      level5: 322
    },
    establishedPatient: {
      level1: 0,
      level2: 0,
      level3: 191,
      level4: 2060,
      level5: 1153
    }
  },
  'Advanced Heart Failure and Transplant Cardiology': {
    newPatient: {
      level1: 0,
      level2: 47,
      level3: 761,
      level4: 9344,
      level5: 8676
    },
    establishedPatient: {
      level1: 1849,
      level2: 1021,
      level3: 16509,
      level4: 126481,
      level5: 51572
    }
  },
  'Allergy/ Immunology': {
    newPatient: {
      level1: 0,
      level2: 1143,
      level3: 41171,
      level4: 83896,
      level5: 8431
    },
    establishedPatient: {
      level1: 16659,
      level2: 22512,
      level3: 284851,
      level4: 290094,
      level5: 20921
    }
  },
  Anesthesiology: {
    newPatient: {
      level1: 0,
      level2: 1953,
      level3: 32212,
      level4: 121211,
      level5: 12775
    },
    establishedPatient: {
      level1: 9557,
      level2: 51872,
      level3: 496767,
      level4: 788125,
      level5: 21680
    }
  },
  Audiologist: {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 11,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }
  },
  'Cardiac Surgery': {
    newPatient: {
      level1: 0,
      level2: 1005,
      level3: 6651,
      level4: 17744,
      level5: 21397
    },
    establishedPatient: {
      level1: 849,
      level2: 5006,
      level3: 21402,
      level4: 30308,
      level5: 11130
    }
  },
  Cardiology: {
    newPatient: {
      level1: 0,
      level2: 5885,
      level3: 104713,
      level4: 767662,
      level5: 169807
    },
    establishedPatient: {
      level1: 182485,
      level2: 159477,
      level3: 2036508,
      level4: 8018703,
      level5: 858923
    }
  },
  'Certified Clinical Nurse Specialist': {
    newPatient: {
      level1: 0,
      level2: 192,
      level3: 1790,
      level4: 3654,
      level5: 1906
    },
    establishedPatient: {
      level1: 2435,
      level2: 9576,
      level3: 79339,
      level4: 131525,
      level5: 19377
    }
  },
  'Certified Nurse Midwife': {
    newPatient: {
      level1: 0,
      level2: 70,
      level3: 577,
      level4: 246,
      level5: 20
    },
    establishedPatient: {
      level1: 19,
      level2: 1027,
      level3: 8292,
      level4: 4906,
      level5: 192
    }
  },
  'Certified Registered Nurse Anesthetist (CRNA)': {
    newPatient: {
      level1: 0,
      level2: 2160,
      level3: 1139,
      level4: 1087,
      level5: 214
    },
    establishedPatient: {
      level1: 2456,
      level2: 1627,
      level3: 5034,
      level4: 5460,
      level5: 615
    }
  },
  Chiropractic: {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 0,
      level2: 0,
      level3: 30,
      level4: 50,
      level5: 0
    }
  },
  'Clinic or Group Practice': {
    newPatient: {
      level1: 0,
      level2: 45,
      level3: 116,
      level4: 78,
      level5: 0
    },
    establishedPatient: {
      level1: 176,
      level2: 108,
      level3: 2037,
      level4: 1383,
      level5: 220
    }
  },
  'Clinical Cardiac Electrophysiology': {
    newPatient: {
      level1: 0,
      level2: 504,
      level3: 11690,
      level4: 105503,
      level5: 63947
    },
    establishedPatient: {
      level1: 12133,
      level2: 14278,
      level3: 214307,
      level4: 710920,
      level5: 124924
    }
  },
  'Clinical Laboratory': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 759,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }
  },
  'Colorectal Surgery (Proctology)': {
    newPatient: {
      level1: 0,
      level2: 6173,
      level3: 48034,
      level4: 38685,
      level5: 10655
    },
    establishedPatient: {
      level1: 2105,
      level2: 20815,
      level3: 86899,
      level4: 43884,
      level5: 8822
    }
  },
  'Critical Care (Intensivists)': {
    newPatient: {
      level1: 0,
      level2: 206,
      level3: 5453,
      level4: 27629,
      level5: 11866
    },
    establishedPatient: {
      level1: 2482,
      level2: 3853,
      level3: 63263,
      level4: 162408,
      level5: 39328
    }
  },
  Dentist: {
    newPatient: {
      level1: 0,
      level2: 1001,
      level3: 1014,
      level4: 1228,
      level5: 181
    },
    establishedPatient: {
      level1: 66,
      level2: 1551,
      level3: 3573,
      level4: 1447,
      level5: 383
    }
  },
  Dermatology: {
    newPatient: {
      level1: 0,
      level2: 154354,
      level3: 828867,
      level4: 261347,
      level5: 1976
    },
    establishedPatient: {
      level1: 15445,
      level2: 1011435,
      level3: 5722191,
      level4: 1942509,
      level5: 26275
    }
  },
  'Diagnostic Radiology': {
    newPatient: {
      level1: 0,
      level2: 4300,
      level3: 10435,
      level4: 10651,
      level5: 3511
    },
    establishedPatient: {
      level1: 10219,
      level2: 14187,
      level3: 34478,
      level4: 22257,
      level5: 4136
    }
  },
  'Emergency Medicine': {
    newPatient: {
      level1: 0,
      level2: 25010,
      level3: 154374,
      level4: 101507,
      level5: 11293
    },
    establishedPatient: {
      level1: 85705,
      level2: 85167,
      level3: 482772,
      level4: 392906,
      level5: 33841
    }
  },
  Endocrinology: {
    newPatient: {
      level1: 0,
      level2: 572,
      level3: 22671,
      level4: 207409,
      level5: 58296
    },
    establishedPatient: {
      level1: 17893,
      level2: 36808,
      level3: 417664,
      level4: 2098304,
      level5: 271019
    }
  },
  'Family Practice': {
    newPatient: {
      level1: 58,
      level2: 52922,
      level3: 397839,
      level4: 473111,
      level5: 40711
    },
    establishedPatient: {
      level1: 545800,
      level2: 678081,
      level3: 8949000,
      level4: 15593356,
      level5: 1012316
    }
  },
  Gastroenterology: {
    newPatient: {
      level1: 0,
      level2: 31292,
      level3: 300186,
      level4: 516686,
      level5: 37234
    },
    establishedPatient: {
      level1: 45019,
      level2: 96442,
      level3: 1034921,
      level4: 1386234,
      level5: 127757
    }
  },
  'General Practice': {
    newPatient: {
      level1: 0,
      level2: 4300,
      level3: 23486,
      level4: 24805,
      level5: 6632
    },
    establishedPatient: {
      level1: 32181,
      level2: 61730,
      level3: 464388,
      level4: 447744,
      level5: 42246
    }
  },
  'General Surgery': {
    newPatient: {
      level1: 0,
      level2: 38664,
      level3: 282483,
      level4: 326209,
      level5: 79540
    },
    establishedPatient: {
      level1: 19973,
      level2: 203872,
      level3: 785118,
      level4: 478808,
      level5: 75712
    }
  },
  'Geriatric Medicine': {
    newPatient: {
      level1: 89,
      level2: 681,
      level3: 3247,
      level4: 12454,
      level5: 20760
    },
    establishedPatient: {
      level1: 21756,
      level2: 16800,
      level3: 94842,
      level4: 257781,
      level5: 76594
    }
  },
  'Geriatric Psychiatry': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 279,
      level5: 1075
    },
    establishedPatient: {
      level1: 222,
      level2: 1998,
      level3: 15440,
      level4: 21858,
      level5: 5220
    }
  },
  'Gynecological Oncology': {
    newPatient: {
      level1: 0,
      level2: 170,
      level3: 2261,
      level4: 13535,
      level5: 19780
    },
    establishedPatient: {
      level1: 1517,
      level2: 6215,
      level3: 67371,
      level4: 88633,
      level5: 34668
    }
  },
  'Hand Surgery': {
    newPatient: {
      level1: 0,
      level2: 5385,
      level3: 137463,
      level4: 119113,
      level5: 2074
    },
    establishedPatient: {
      level1: 609,
      level2: 32119,
      level3: 280512,
      level4: 196752,
      level5: 4139
    }
  },
  Hematology: {
    newPatient: {
      level1: 0,
      level2: 58,
      level3: 1130,
      level4: 7419,
      level5: 14112
    },
    establishedPatient: {
      level1: 1598,
      level2: 2832,
      level3: 50258,
      level4: 160105,
      level5: 71036
    }
  },
  'Hematology-Oncology': {
    newPatient: {
      level1: 0,
      level2: 1394,
      level3: 29043,
      level4: 167979,
      level5: 218023
    },
    establishedPatient: {
      level1: 73906,
      level2: 100718,
      level3: 1377584,
      level4: 3122671,
      level5: 1043012
    }
  },
  'Hematopoietic Cell Transplantation and Cellular Therapy': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 89,
      level4: 586,
      level5: 1545
    },
    establishedPatient: {
      level1: 204,
      level2: 414,
      level3: 4087,
      level4: 18745,
      level5: 13842
    }
  },
  'Hospice and Palliative Care': {
    newPatient: {
      level1: 0,
      level2: 54,
      level3: 491,
      level4: 3118,
      level5: 9102
    },
    establishedPatient: {
      level1: 478,
      level2: 2238,
      level3: 13398,
      level4: 32874,
      level5: 26200
    }
  },
  Hospitalist: {
    newPatient: {
      level1: 0,
      level2: 3833,
      level3: 6476,
      level4: 21363,
      level5: 9284
    },
    establishedPatient: {
      level1: 13206,
      level2: 8389,
      level3: 68940,
      level4: 133306,
      level5: 21522
    }
  },
  'Infectious Disease': {
    newPatient: {
      level1: 0,
      level2: 567,
      level3: 7318,
      level4: 28230,
      level5: 12251
    },
    establishedPatient: {
      level1: 10582,
      level2: 17076,
      level3: 159424,
      level4: 294338,
      level5: 54388
    }
  },
  'Internal Medicine': {
    newPatient: {
      level1: 205,
      level2: 34447,
      level3: 193569,
      level4: 534943,
      level5: 146911
    },
    establishedPatient: {
      level1: 618858,
      level2: 911391,
      level3: 8415891,
      level4: 15402362,
      level5: 1601159
    }
  },
  'Interventional Cardiology': {
    newPatient: {
      level1: 0,
      level2: 1140,
      level3: 23235,
      level4: 199635,
      level5: 49145
    },
    establishedPatient: {
      level1: 25591,
      level2: 25871,
      level3: 381577,
      level4: 1692756,
      level5: 170580
    }
  },
  'Interventional Pain Management': {
    newPatient: {
      level1: 0,
      level2: 535,
      level3: 26192,
      level4: 84838,
      level5: 6961
    },
    establishedPatient: {
      level1: 16344,
      level2: 36946,
      level3: 460433,
      level4: 648250,
      level5: 17133
    }
  },
  'Interventional Radiology': {
    newPatient: {
      level1: 0,
      level2: 1170,
      level3: 7505,
      level4: 10479,
      level5: 2624
    },
    establishedPatient: {
      level1: 1292,
      level2: 7294,
      level3: 32659,
      level4: 23873,
      level5: 3299
    }
  },
  'Licensed Clinical Social Worker': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 11,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 0,
      level2: 0,
      level3: 477,
      level4: 161,
      level5: 70
    }
  },
  'Mass Immunizer Roster Biller': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 1978,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }
  },
  'Maxillofacial Surgery': {
    newPatient: {
      level1: 0,
      level2: 5575,
      level3: 4892,
      level4: 2971,
      level5: 1000
    },
    establishedPatient: {
      level1: 419,
      level2: 3566,
      level3: 7989,
      level4: 5659,
      level5: 1128
    }
  },
  'Medical Genetics and Genomics': {
    newPatient: {
      level1: 0,
      level2: 103,
      level3: 237,
      level4: 443,
      level5: 741
    },
    establishedPatient: {
      level1: 0,
      level2: 13,
      level3: 174,
      level4: 266,
      level5: 300
    }
  },
  'Medical Oncology': {
    newPatient: {
      level1: 0,
      level2: 255,
      level3: 7850,
      level4: 43368,
      level5: 81811
    },
    establishedPatient: {
      level1: 17556,
      level2: 29954,
      level3: 407653,
      level4: 971379,
      level5: 403587
    }
  },
  'Medical Toxicology': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 37,
      level4: 22,
      level5: 35
    },
    establishedPatient: {
      level1: 0,
      level2: 0,
      level3: 11,
      level4: 0,
      level5: 0
    }
  },
  'Micrographic Dermatologic Surgery': {
    newPatient: {
      level1: 0,
      level2: 3298,
      level3: 19298,
      level4: 11276,
      level5: 97
    },
    establishedPatient: {
      level1: 1100,
      level2: 28719,
      level3: 101125,
      level4: 31169,
      level5: 315
    }
  },
  Nephrology: {
    newPatient: {
      level1: 104,
      level2: 746,
      level3: 18456,
      level4: 156155,
      level5: 60621
    },
    establishedPatient: {
      level1: 23652,
      level2: 44900,
      level3: 483166,
      level4: 1809641,
      level5: 297832
    }
  },
  Neurology: {
    newPatient: {
      level1: 0,
      level2: 3368,
      level3: 55281,
      level4: 429262,
      level5: 332111
    },
    establishedPatient: {
      level1: 22476,
      level2: 58103,
      level3: 795942,
      level4: 2086742,
      level5: 759169
    }
  },
  Neuropsychiatry: {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 259,
      level4: 1325,
      level5: 1283
    },
    establishedPatient: {
      level1: 146,
      level2: 1089,
      level3: 8989,
      level4: 11747,
      level5: 3738
    }
  },
  Neurosurgery: {
    newPatient: {
      level1: 0,
      level2: 7114,
      level3: 84237,
      level4: 157779,
      level5: 39001
    },
    establishedPatient: {
      level1: 7895,
      level2: 55002,
      level3: 258733,
      level4: 242828,
      level5: 53978
    }
  },
  'Nuclear Medicine': {
    newPatient: {
      level1: 0,
      level2: 69,
      level3: 732,
      level4: 1933,
      level5: 323
    },
    establishedPatient: {
      level1: 176,
      level2: 736,
      level3: 5085,
      level4: 17792,
      level5: 2115
    }
  },
  'Nurse Practitioner': {
    newPatient: {
      level1: 1061,
      level2: 135967,
      level3: 941244,
      level4: 808230,
      level5: 121906
    },
    establishedPatient: {
      level1: 925431,
      level2: 1055428,
      level3: 9190607,
      level4: 11713326,
      level5: 1057835
    }
  },
  'Obstetrics & Gynecology': {
    newPatient: {
      level1: 0,
      level2: 6816,
      level3: 67772,
      level4: 102212,
      level5: 26014
    },
    establishedPatient: {
      level1: 17723,
      level2: 90053,
      level3: 741998,
      level4: 489085,
      level5: 58365
    }
  },
  Ophthalmology: {
    newPatient: {
      level1: 0,
      level2: 9985,
      level3: 147714,
      level4: 894387,
      level5: 33971
    },
    establishedPatient: {
      level1: 22976,
      level2: 264864,
      level3: 2667111,
      level4: 3068984,
      level5: 83029
    }
  },
  Optometry: {
    newPatient: {
      level1: 0,
      level2: 8599,
      level3: 95660,
      level4: 203090,
      level5: 6973
    },
    establishedPatient: {
      level1: 5108,
      level2: 271757,
      level3: 1641158,
      level4: 1077873,
      level5: 20088
    }
  },
  'Oral Surgery (Dentist only)': {
    newPatient: {
      level1: 0,
      level2: 5761,
      level3: 9085,
      level4: 6964,
      level5: 2071
    },
    establishedPatient: {
      level1: 2930,
      level2: 5539,
      level3: 15196,
      level4: 12680,
      level5: 1804
    }
  },
  'Orthopedic Surgery': {
    newPatient: {
      level1: 0,
      level2: 28302,
      level3: 939811,
      level4: 846781,
      level5: 39996
    },
    establishedPatient: {
      level1: 18625,
      level2: 430700,
      level3: 3839429,
      level4: 2539531,
      level5: 138876
    }
  },
  'Osteopathic Manipulative Medicine': {
    newPatient: {
      level1: 0,
      level2: 673,
      level3: 5366,
      level4: 7229,
      level5: 755
    },
    establishedPatient: {
      level1: 1630,
      level2: 12749,
      level3: 91797,
      level4: 67687,
      level5: 8795
    }
  },
  Otolaryngology: {
    newPatient: {
      level1: 0,
      level2: 41840,
      level3: 667417,
      level4: 500156,
      level5: 22605
    },
    establishedPatient: {
      level1: 13649,
      level2: 185584,
      level3: 1754949,
      level4: 1063134,
      level5: 41858
    }
  },
  'Pain Management': {
    newPatient: {
      level1: 0,
      level2: 4173,
      level3: 33154,
      level4: 151162,
      level5: 12969
    },
    establishedPatient: {
      level1: 14377,
      level2: 49629,
      level3: 586402,
      level4: 922711,
      level5: 32081
    }
  },
  Pathology: {
    newPatient: {
      level1: 0,
      level2: 2230,
      level3: 3369,
      level4: 1250,
      level5: 178
    },
    establishedPatient: {
      level1: 4539,
      level2: 2025,
      level3: 12362,
      level4: 7510,
      level5: 469
    }
  },
  'Pediatric Medicine': {
    newPatient: {
      level1: 0,
      level2: 1174,
      level3: 10081,
      level4: 8132,
      level5: 1371
    },
    establishedPatient: {
      level1: 5446,
      level2: 6399,
      level3: 63928,
      level4: 91246,
      level5: 9650
    }
  },
  'Peripheral Vascular Disease': {
    newPatient: {
      level1: 0,
      level2: 98,
      level3: 1562,
      level4: 2551,
      level5: 464
    },
    establishedPatient: {
      level1: 259,
      level2: 808,
      level3: 5444,
      level4: 8827,
      level5: 910
    }
  },
  'Physical Medicine and Rehabilitation': {
    newPatient: {
      level1: 0,
      level2: 11446,
      level3: 106384,
      level4: 237458,
      level5: 28078
    },
    establishedPatient: {
      level1: 16197,
      level2: 77670,
      level3: 830082,
      level4: 1074515,
      level5: 68639
    }
  },
  'Physical Therapist in Private Practice': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 20,
      level4: 30,
      level5: 0
    },
    establishedPatient: {
      level1: 0,
      level2: 16,
      level3: 42,
      level4: 36,
      level5: 0
    }
  },
  'Physician Assistant': {
    newPatient: {
      level1: 617,
      level2: 129491,
      level3: 1080841,
      level4: 638387,
      level5: 40952
    },
    establishedPatient: {
      level1: 164001,
      level2: 803644,
      level3: 6541486,
      level4: 5395587,
      level5: 392461
    }
  },
  'Plastic and Reconstructive Surgery': {
    newPatient: {
      level1: 0,
      level2: 17081,
      level3: 77312,
      level4: 44637,
      level5: 6859
    },
    establishedPatient: {
      level1: 3480,
      level2: 62557,
      level3: 154235,
      level4: 57143,
      level5: 8279
    }
  },
  Podiatry: {
    newPatient: {
      level1: 6729,
      level2: 166531,
      level3: 1082803,
      level4: 229611,
      level5: 2974
    },
    establishedPatient: {
      level1: 14489,
      level2: 975501,
      level3: 4390668,
      level4: 656777,
      level5: 11046
    }
  },
  'Preventive Medicine': {
    newPatient: {
      level1: 0,
      level2: 472,
      level3: 2101,
      level4: 3421,
      level5: 1028
    },
    establishedPatient: {
      level1: 2137,
      level2: 2370,
      level3: 17391,
      level4: 16119,
      level5: 3441
    }
  },
  Psychiatry: {
    newPatient: {
      level1: 0,
      level2: 476,
      level3: 1826,
      level4: 16843,
      level5: 19649
    },
    establishedPatient: {
      level1: 37514,
      level2: 244551,
      level3: 1366788,
      level4: 1567061,
      level5: 158010
    }
  },
  'Psychologist, Clinical': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 40,
      level5: 198
    },
    establishedPatient: {
      level1: 0,
      level2: 120,
      level3: 812,
      level4: 284,
      level5: 500
    }
  },
  'Public Health or Welfare Agency': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 351,
      level2: 0,
      level3: 0,
      level4: 25,
      level5: 22
    }
  },
  'Pulmonary Disease': {
    newPatient: {
      level1: 0,
      level2: 1424,
      level3: 54194,
      level4: 300850,
      level5: 99528
    },
    establishedPatient: {
      level1: 27569,
      level2: 55206,
      level3: 788027,
      level4: 1950764,
      level5: 314293
    }
  },
  'Radiation Oncology': {
    newPatient: {
      level1: 0,
      level2: 654,
      level3: 8632,
      level4: 67851,
      level5: 169566
    },
    establishedPatient: {
      level1: 8154,
      level2: 54804,
      level3: 237898,
      level4: 149647,
      level5: 69713
    }
  },
  'Radiation Therapy Center': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 128
    }
  },
  'Registered Dietitian or Nutrition Professional': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 11,
      level5: 0
    },
    establishedPatient: {
      level1: 38,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 35
    }
  },
  Rheumatology: {
    newPatient: {
      level1: 0,
      level2: 764,
      level3: 19003,
      level4: 187678,
      level5: 68579
    },
    establishedPatient: {
      level1: 9824,
      level2: 44342,
      level3: 557246,
      level4: 1859943,
      level5: 194463
    }
  },
  'Sleep Medicine': {
    newPatient: {
      level1: 0,
      level2: 400,
      level3: 9481,
      level4: 37236,
      level5: 7639
    },
    establishedPatient: {
      level1: 3717,
      level2: 4213,
      level3: 68184,
      level4: 110879,
      level5: 19031
    }
  },
  'Speech Language Pathologist': {
    newPatient: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    },
    establishedPatient: {
      level1: 260,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0
    }
  },
  'Sports Medicine': {
    newPatient: {
      level1: 0,
      level2: 1028,
      level3: 80452,
      level4: 80591,
      level5: 1612
    },
    establishedPatient: {
      level1: 1574,
      level2: 18011,
      level3: 243636,
      level4: 208710,
      level5: 9103
    }
  },
  'Surgical Oncology': {
    newPatient: {
      level1: 0,
      level2: 540,
      level3: 5824,
      level4: 17357,
      level5: 22101
    },
    establishedPatient: {
      level1: 873,
      level2: 9007,
      level3: 59868,
      level4: 40351,
      level5: 14637
    }
  },
  'Thoracic Surgery': {
    newPatient: {
      level1: 0,
      level2: 1248,
      level3: 10958,
      level4: 36694,
      level5: 41055
    },
    establishedPatient: {
      level1: 3337,
      level2: 10712,
      level3: 58216,
      level4: 47477,
      level5: 18060
    }
  },
  'Undefined Physician type': {
    newPatient: {
      level1: 0,
      level2: 197,
      level3: 2575,
      level4: 5010,
      level5: 1507
    },
    establishedPatient: {
      level1: 536,
      level2: 3694,
      level3: 17615,
      level4: 19343,
      level5: 3712
    }
  },
  'Undersea and Hyperbaric Medicine': {
    newPatient: {
      level1: 0,
      level2: 178,
      level3: 2054,
      level4: 2464,
      level5: 480
    },
    establishedPatient: {
      level1: 129,
      level2: 4351,
      level3: 28030,
      level4: 9845,
      level5: 250
    }
  },
  'Unknown Supplier/Provider Specialty': {
    newPatient: {
      level1: 0,
      level2: 41,
      level3: 14,
      level4: 21,
      level5: 0
    },
    establishedPatient: {
      level1: 0,
      level2: 0,
      level3: 49,
      level4: 154,
      level5: 15
    }
  },
  Urology: {
    newPatient: {
      level1: 0,
      level2: 7694,
      level3: 211078,
      level4: 598622,
      level5: 36082
    },
    establishedPatient: {
      level1: 89737,
      level2: 226323,
      level3: 2336057,
      level4: 2746109,
      level5: 152180
    }
  },
  'Vascular Surgery': {
    newPatient: {
      level1: 0,
      level2: 9315,
      level3: 107549,
      level4: 120147,
      level5: 17983
    },
    establishedPatient: {
      level1: 10178,
      level2: 77044,
      level3: 429559,
      level4: 271539,
      level5: 29302
    }
  }
}

export { medicare, bySpecialty, mapping }