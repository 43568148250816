<template>
  <div>
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Projects</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/pro-risk-analysis' }">Professional Fee Risk Analysis</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row>
      <el-card style="min-height: 50vh">
        <el-row>
          <el-col :span="24">
            <div style="display: flex; align-items: center;">
              <!-- <div>
                <el-button style="grid-column: 1/2; width: 100px" :disabled="readOnly() && !projectManagement()" class="hiaButton" icon="mdi mdi-file-upload-outline" round plain size="mini" @click="handleSelect"> Select</el-button>
              </div>
              <div>
                <el-button style="grid-column: 1/2; width: 100px" :disabled="readOnly() && !projectManagement()" class="hiaButton" round plain size="mini" @click="processClaimsImport"> Import Claims</el-button>
              </div> -->
              <!-- <div>
                <el-button style="grid-column: 1/2; width: 100px" :disabled="readOnly() && !projectManagement()" class="hiaButton" round plain size="mini" @click="loadClaims"> Load Claims</el-button>
              </div> -->
              <div style="display: grid; grid-template-columns: auto auto">
                <el-date-picker class="hiaRangePicker" size="mini" format="MM/dd/yyyy" value-format="MM/dd/yyyy" v-model="localdateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" @change="handleCriteriaChange" style="z-index: 1;" :picker-options="dateRangePickerConfig" clearable> </el-date-picker>
              </div>
              <!-- <div>
                <span slot="reference" style="margin-left: 10px;">{{ importFileNewName }}</span>
                <input id="importFileNew" style="display: none;" name="txttoImport" type="file" @change="onFileChange" />
              </div> -->
              <i title="Filter Section" class="mdi mdi-filter-variant filterIcon"></i>
              <div style="margin: 0px 5px 0px 0px; display: grid; grid-template-columns: auto auto">
                <hia-el-select title="EM Category" v-model="emCategorySelect" placeholder="EM Category" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.proRiskEMCategories.list" @input="handleEmCategorySelect"></hia-el-select>
              </div>
              <div style="margin: 0px 5px 0px 5px;display: grid; grid-template-columns: auto auto">
                <el-select id="providerSpecialties" key="providerSpecialties" element-loading-spinner="none" v-model="providerSpecialtiesSelect" placeholder="Provider Specialties" :clearable="true" multiple :collapse-tags="true" @remove-tag="handleProjectStatusMultiSelect()" @input="handleProjectStatusMultiSelect">
                  <el-option v-for="item in dropdowns.proRiskProviderSpecialties.list" :key="item.key" :label="item.value" :value="item.value"> </el-option>
                </el-select>
              </div>
            </div>
            <div style="float: right; margin-top: -20px;">
              <input
                type="checkbox"
                v-model="medicareCompare"
                @change="
                  () => {
                    renderOverallDistribution()
                    renderTableCharts('all')
                  }
                "
              />
              Medicare Compare
            </div>
          </el-col>

          <el-col :span="24" style="margin-top: 20px;">
            <el-alert v-if="overallDistribution.count > 0" type="success" :closable="false">{{ overallDistribution.count }} claims loaded</el-alert>
            <!-- <el-alert v-if="claims.length === 0" type="warning"><span style="font-weight: bold;">Select an 837P file to perform analysis</span></el-alert> -->
          </el-col>
          <el-row v-if="!overallDistribution.count" style="opacity: .2; margin-top: 90px;">
            <h3>Overall Distribution</h3>
            <el-col :span="24">
              <div id="tempContainer1"></div>
            </el-col>

            <!-- <el-col :span="12">
              <div id="tempContainer2"></div>
            </el-col> -->
          </el-row>

          <template>
            <el-row v-show="overallDistribution.count" style="margin-top: 80px;">
              <h3>
                Overall Distribution
                <div class="clickable" @click="handleShowModalAll()" style="margin-right: 10px; font-size: 16px;"><i class="mdi mdi-magnify-plus-outline"></i></div>
              </h3>
            </el-row>
            <el-row v-show="overallDistribution.count">
              <el-col :span="24">
                <div id="container1"></div>
              </el-col>

              <!-- <el-col :span="12">
                <div id="container2"></div>
              </el-col> -->
            </el-row>

            <el-row style="margin-top: 60px;">
              <el-tabs class="dataTabs" v-model="activeTab" @tab-click="renderTableCharts">
                <el-tab-pane label="Distribution by Provider" name="distributionByProvider">
                  <v-server-table style="min-height: 120px;" ref="distributionProvidersGrid" id="distributionProvidersGrid" :columns="providerColumns" :options="providerOptions" class="hiaTable" element-loading-spinner="atom-audit-loader" @loading="providerTableLoader = true" @loaded="providerTableLoader = false" v-loading="providerTableLoader">
                    <template slot="providerName" slot-scope="props">
                      <div class="clickable" @click="handleShowModal(props.row)" style="margin-right: 10px;"><i class="mdi mdi-magnify-plus-outline"></i></div>
                      {{ props.row.providerName }}
                    </template>

                    <template slot="claimsTotal" slot-scope="props">
                      {{ props.row.claimsTotal }}
                    </template>

                    <template slot="specialty" slot-scope="props">
                      {{ props.row.specialty }}
                    </template>

                    <template slot="newPatient" slot-scope="props">
                      <div class="level-row">
                        <div class="level-cell">{{ props.row.newLevel1 }}</div>
                        <div class="level-cell">{{ props.row.newLevel2 }}</div>
                        <div class="level-cell">{{ props.row.newLevel3 }}</div>
                        <div class="level-cell">{{ props.row.newLevel4 }}</div>
                        <div class="level-cell">{{ props.row.newLevel5 }}</div>
                      </div>
                      <div class="level-row">
                        <div class="level-cell">Total</div>
                        <div class="level-cell">{{ props.row.newTotal }}</div>
                      </div>
                    </template>

                    <template slot="establishedPatient" slot-scope="props">
                      <div class="level-row">
                        <div class="level-cell">{{ props.row.establishedLevel1 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel2 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel3 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel4 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel5 }}</div>
                      </div>
                      <div class="level-row">
                        <div class="level-cell">Total</div>
                        <div class="level-cell">{{ props.row.establishedTotal }}</div>
                      </div>
                    </template>

                    <template slot="newPatientChart" slot-scope="props">
                      <div :id="`newPatientsByProvider${props.row.providerName}`"></div>
                    </template>

                    <template slot="establishedPatientChart" slot-scope="props">
                      <div :id="`establishedPatientsByProvider${props.row.providerName}`"></div>
                    </template>
                  </v-server-table>
                </el-tab-pane>

                <el-tab-pane label="Distribution by Specialty" name="distributionBySpecialty">
                  <v-server-table style="min-height: 120px;" ref="distributionSpecialtiesGrid" id="distributionSpecialtiesGrid" :columns="specialtyColumns" :options="specialtyOptions" class="hiaTable" element-loading-spinner="atom-audit-loader" @loading="specialtyTableLoader = true" @loaded="specialtyTableLoader = false" v-loading="specialtyTableLoader">
                    <template slot="specialty" slot-scope="props">
                      <div class="clickable" @click="handleShowModal(props.row)" style="margin-right: 10px;"><i class="mdi mdi-magnify-plus-outline"></i></div>
                      {{ props.row.specialty }}
                    </template>

                    <template slot="claimsTotal" slot-scope="props">
                      {{ props.row.claimsTotal }}
                    </template>

                    <template slot="newPatient" slot-scope="props">
                      <div class="level-row">
                        <div class="level-cell">{{ props.row.newLevel1 }}</div>
                        <div class="level-cell">{{ props.row.newLevel2 }}</div>
                        <div class="level-cell">{{ props.row.newLevel3 }}</div>
                        <div class="level-cell">{{ props.row.newLevel4 }}</div>
                        <div class="level-cell">{{ props.row.newLevel5 }}</div>
                      </div>
                      <div class="level-row">
                        <div class="level-cell">Total</div>
                        <div class="level-cell">{{ props.row.newTotal }}</div>
                      </div>
                    </template>

                    <template slot="establishedPatient" slot-scope="props">
                      <div class="level-row">
                        <div class="level-cell">{{ props.row.establishedLevel1 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel2 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel3 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel4 }}</div>
                        <div class="level-cell">{{ props.row.establishedLevel5 }}</div>
                      </div>
                      <div class="level-row">
                        <div class="level-cell">Total</div>
                        <div class="level-cell">{{ props.row.establishedTotal }}</div>
                      </div>
                    </template>

                    <template slot="newPatientChart" slot-scope="props">
                      <div :id="`newPatientsBySpecialty${props.row.specialty}`"></div>
                    </template>

                    <template slot="establishedPatientChart" slot-scope="props">
                      <div :id="`establishedPatientsBySpecialty${props.row.specialty}`"></div>
                    </template>
                  </v-server-table>
                </el-tab-pane>
              </el-tabs>
            </el-row>
          </template>
        </el-row>
      </el-card>
    </el-row>

    <div id="dialogs">
      <el-dialog v-if="showModal" width="98%" top="2vh" :title="`Risk Analysis for ${activeTab === 'distributionByProvider' ? modalData.providerName : modalData.specialty}`" :visible.sync="showModal" :destroy-on-close="true" :before-close="closeDistributionDialog">
        <el-row style="margin-top: 20px;">
          <h3>Distribution</h3>
          <div style="float: right; margin-top: -20px;"><input type="checkbox" v-model="medicareCompare" @change="handleShowModal(modalData)" /> Medicare Compare</div>
          <el-col :span="12">
            <div id="modalContainer1"></div>
          </el-col>

          <el-col :span="12">
            <div id="modalContainer2"></div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 20px;" L>
          <el-form size="mini" ref="auditSample" label-position="left" label-width="180px">
            <el-row :gutter="20">
              <el-col :span="12">
                <h3 class="sectionHeader">Create Audit Sample</h3>
                <el-form-item v-if="activeTab === 'distributionByProvider'" label="Provider Name">
                  {{ modalData.providerName }}
                </el-form-item>
                <el-form-item label="Specialty">
                  {{ modalData.specialty }}
                </el-form-item>
                <el-form-item label="Total Claims">
                  {{ modalData.claimsTotal }}
                </el-form-item>
                <el-form-item prop="sampleSize" label="Random Sample Size">
                  <el-input style="width: 75%" v-model="sampleForm.size" size="mini"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <h3 class="sectionHeader">Sample Distribution</h3>
                <el-col :span="12">
                  <div id="sampleContainer1"></div>
                </el-col>

                <el-col :span="12">
                  <div id="sampleContainer2"></div>
                </el-col>
              </el-col>
            </el-row>
          </el-form>
          <el-row :gutter="20">
            <el-col :span="12">
              <h3 class="sectionHeader">
                All Records ({{ modalData.claimsTotal }})
                <el-button-group style="margin-top: -10px; float: right;">
                  <el-button class="hiaButton" plain round size="mini" @click="addSelected">Add Selected</el-button>
                  <el-button class="hiaButton" plain round size="mini" @click="getProRiskRecordsDataRandom">Generate Random Sample</el-button>
                </el-button-group>
              </h3>

              <el-card>
                <v-server-table style="min-height: 120px;" ref="proRiskRecordsGrid" id="proRiskRecordsGrid" :columns="proRiskRecordColumns" :options="proRiskRecordOptions" class="hiaTable" element-loading-spinner="atom-audit-loader" @loading="proRecordTableLoader = true" @loaded="proRecordTableLoader = false" v-loading="proRecordTableLoader">
                  <template slot="selected" slot-scope="props">
                    <input type="checkbox" v-model="props.row.selected" @click="toggleSelection(props.row, $event)" />
                  </template>
                  <template slot="dateOfService" slot-scope="props">
                    <div>
                      {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
                    </div>
                  </template>
                </v-server-table>
              </el-card>
            </el-col>
            <el-col :span="12">
              <h3 class="sectionHeader">
                Sampled Records ({{ sampleForm.claims.length }})
                <el-button-group style="margin-top: -10px; float: right;">
                  <el-button class="hiaButton" plain round size="mini" @click="sampleForm.claims = []">Clear Sample</el-button>
                  <el-button class="hiaButton" plain round size="mini" :disabled="sampleForm.claims.length === 0" @click="openImportSamplePopover">Import Sample</el-button>
                </el-button-group>

                <el-popover placement="top" ref="importSamplePopover" title="Import Sample into Project" width="400">
                  <el-form size="mini" id="addForm" :inline="true" ref="addForm" label-position="left" label-width="180px" v-loading="isPosting" element-loading-spinner="atom-audit-loader">
                    <el-form-item prop="targetProject" label="Target Project">
                      <projectSelector style="margin-top: 10px; width: 350px;" :allowAdd="true" :clearable="false" @selected="sampleForm.project = $event" v-model="sampleForm.projectId" :project="sampleForm.project" :defaultQuery="{ projectTypeId: 1002 }"> </projectSelector>
                    </el-form-item>

                    <center>
                      <el-button-group style="margin-top: 10px;">
                        <el-button class="hiaButton" plain round type="text" size="mini" @click="closeImportSamplePopover">Cancel</el-button>
                        <el-button class="hiaButton" round :disabled="!sampleForm.project" size="mini" @click="importRecords">Import </el-button>
                      </el-button-group>
                    </center>
                  </el-form>
                </el-popover>
              </h3>

              <el-card>
                <v-client-table class="hiaTable" ref="sampleGrid" id="sampleGrid" :data="sampleForm.claims" :columns="sampleColumns" :options="sampleOptions" @filter="handleTableChange" @sorted="handleTableChange">
                  <template slot="dateOfService" slot-scope="props">
                    <div>
                      {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
                    </div>
                  </template>
                  <template slot="delete" slot-scope="props">
                    <div style="text-align: center;">
                      <i class="el-icon-close delete-button" title="Delete Claim" @click="deleteClaim(props.row)"></i>
                    </div>
                  </template>
                </v-client-table>
              </el-card>
            </el-col>
          </el-row>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
  import processFile from '@/utilities/process837p'
  // import sampleText from './sampleFile'
  import projectSelector from '@/components/controls/projectSelector'
  import { format, parseISO } from 'date-fns'
  import dateHelper from '@/mixins/date-helper'
  import { medicare, bySpecialty, mapping } from './medicareData.js'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'ProRiskAnalysis',
    mixins: [dateHelper, HiaAuthorization, hiaTable],
    data: function() {
      return {
        format: format,
        parseISO: parseISO,
        importFileNewName: null,
        medicareCompare: false,
        inputFiles: [],
        claims: [],
        providers: [],
        highCharts: null,
        activeTab: 'distributionByProvider',
        isPosting: false,
        columns: ['providerName', 'providerSpecialty', 'numberOfClaims', 'numNewPatientCodes', 'numEstablishedPatientCodes', 'newPatientChart', 'establishedPatientChart'],
        options: {
          filterByColumn: true,
          headings: {
            providerName: 'Provider',
            providerSpecialty: 'Specialty',
            numberOfClaims: 'Claims',
            numNewPatientCodes: 'New Patient Codes',
            numEstablishedPatientCodes: 'Established Patient Codes',
            newPatientChart: 'New Patient EM Level Distribution',
            establishedPatientChart: 'Established Patient EM Level Distribution',
            selected: ''
          },
          columnsClasses: {
            newPatientChart: 'twentyPercent',
            establishedPatientChart: 'twentyPercent'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        providerTableLoader: false,
        specialtyTableLoader: false,
        proRecordTableLoader: false,
        vueTables2Query: {},
        providerColumns: ['providerName', 'specialty', 'claimsTotal', 'newPatient', 'establishedPatient', 'newPatientChart', 'establishedPatientChart'],
        providerOptions: {
          requestFunction: data => {
            return this.requestDistributionByProviderData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: ['providerName', 'specialty', 'claimsTotal', 'newPatient', 'establishedPatient'],
          orderBy: {
            column: 'providerName',
            ascending: true
          },
          columnsClasses: {
            newPatientChart: 'twentyPercent',
            establishedPatientChart: 'twentyPercent'
          },
          headings: {
            providerName: 'Provider',
            specialty: 'Specialty',
            claimsTotal: 'Claims',
            newPatient: 'New Patient Codes',
            establishedPatient: 'Established Patient Codes',
            newPatientChart: 'New Patient EM Level Distribution',
            establishedPatientChart: 'Established Patient EM Level Distribution',
            selected: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        modalData: {},
        showModal: false,
        sampleForm: {
          size: 10,
          projectId: null,
          project: {},
          claims: []
        },
        specialtyColumns: ['specialty', 'claimsTotal', 'newPatient', 'establishedPatient', 'newPatientChart', 'establishedPatientChart'],
        specialtyOptions: {
          requestFunction: data => {
            return this.requestDistributionBySpecialtyData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: ['specialty', 'claimsTotal', 'newPatient', 'establishedPatient'],
          orderBy: {
            column: 'specialty',
            ascending: true
          },
          columnsClasses: {
            newPatientChart: 'twentyPercent',
            establishedPatientChart: 'twentyPercent'
          },
          headings: {
            specialty: 'Specialty',
            claimsTotal: 'Claims',
            newPatient: 'New Patient Codes',
            establishedPatient: 'Established Patient Codes',
            newPatientChart: 'New Patient EM Level Distribution',
            establishedPatientChart: 'Established Patient EM Level Distribution',
            selected: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        proRiskRecordColumns: ['selected', 'claimId', 'dateOfService', 'dxCodesDisplay', 'cptCodesDisplay', 'emLevel'],
        proRiskRecordOptions: {
          requestFunction: data => {
            return this.requestProRiskRecordsData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: ['claimId', 'dateOfService', 'dxCodesDisplay', 'cptCodesDisplay', 'emLevel'],
          orderBy: {
            column: 'claimId',
            ascending: true
          },
          columnsClasses: {
            // newPatientChart: 'twentyPercent',
            // establishedPatientChart: 'twentyPercent'
          },
          headings: {
            claimId: 'claim Id',
            dateOfService: 'Date of Service',
            dxCodesDisplay: 'Dxs',
            cptCodesDisplay: 'CPTs',
            emLevel: 'EM Level',
            selected: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        sampleColumns: ['claimId', 'dateOfService', 'dxCodesDisplay', 'cptCodesDisplay', 'emLevel', 'delete'],
        sampleOptions: {
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: ['claimId', 'dateOfService', 'dxCodesDisplay', 'cptCodesDisplay', 'emLevel'],
          orderBy: {
            column: 'claimId',
            ascending: true
          },
          columnsClasses: {
            // newPatientChart: 'twentyPercent',
            // establishedPatientChart: 'twentyPercent'
          },
          headings: {
            claimId: 'claim Id',
            dateOfService: 'Date of Service',
            dxCodesDisplay: 'Dxs',
            cptCodesDisplay: 'CPTs',
            emLevel: 'EM Level',
            delete: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        localdateRange: [],
        overallDistribution: {
          count: 0,
          newPatient: {},
          establishedPatient: {}
        },
        localProviderDistribution: [],
        localSpecialtyDistribution: [],
        localProRiskRecords: {
          count: 0,
          data: [],
          selections: []
        },
        selectionData: [],
        dropdownsNeeded: ['proRiskEMCategories', 'proRiskProviderSpecialties'],
        emCategorySelect: null,
        providerSpecialtiesSelect: null
      }
    },
    components: { projectSelector },
    methods: {
      ...mapActions('proReviews/', ['POST_PRO_TEMPLATE_MULTIPLE', 'POST_PRO_RISK_ANALYSIS', 'LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED', 'LOAD_DISTRIBUTION_BY_SPECIALTY_LIST_PAGINATED', 'LOAD_OVERALL_DISTRIBUTION', 'LOAD_PROVIDER_RECORDS', 'LOAD_PRO_RISK_RECORD_LIST_PAGINATED', 'LOAD_PRO_RISK_RECORD_RANDOM_LIST']),
      ...mapMutations('globals/', ['SET_DATERANGE']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      async handleTableChange(e) {
        await this.$nextTick()
        await this.renderTableCharts(null)
      },
      async renderSampledDistributions() {
        const sampleEmCodeDistribution = this.calculateEMCodeDistribution(this.sampleForm.claims)

        this.createMiniBellCurveChart(sampleEmCodeDistribution.newPatient, [], 'sampleContainer1')
        this.createMiniBellCurveChart(sampleEmCodeDistribution.establishedPatient, [], 'sampleContainer2')
      },
      async addSelected() {
        // this.sampleForm.claims = this.sampleForm.claims.concat(this.modalData.claims.filter(x => x.selected))
        this.sampleForm.claims = this.selectionData.filter(x => x.selected)

        await this.$nextTick()
        await this.renderSampledDistributions()
      },
      openImportSamplePopover(e) {
        const popper = this.$refs.importSamplePopover
        this.popperClickTarget = e.target
        popper.doDestroy()
        popper.referenceElm = e.target
        popper.doShow()
      },
      closeImportSamplePopover() {
        const popper = this.$refs.importSamplePopover
        if (popper) {
          popper.doDestroy()
          popper.doClose()
        }
      },
      // selectRecord(row, e) {
      //   const index = this.modalData.claims.findIndex(x => JSON.stringify(x) === JSON.stringify(row))
      //   const checked = e.srcElement.checked
      //   row.selected = checked
      //   this.$set(this.modalData.claims, index, row)
      // },
      toggleSelection(row, e) {
        setTimeout(() => {
          if (row.selected) {
            this.addSelection(row)
          } else {
            this.deleteSelection(row)
          }
        }, 5)
      },
      addSelection(row) {
        this.selectionData.push(row)
      },
      deleteSelection(row) {
        const index = this.selectionData.findIndex(f => f.recordId === row.recordId)
        if (index !== -1) {
          this.selectionData.splice(index, 1)
          const sourceIndex = this.$refs.proRiskRecordsGrid.data.findIndex(f => f.recordId === row.recordId)
          if (sourceIndex !== -1) {
            this.$refs.proRiskRecordsGrid.data[sourceIndex].selected = false
          }
        }
      },
      // handleSelect() {
      //   this.resetFileInput()
      //   const input = document.getElementById('importFileNew')
      //   input.click()
      // },
      async handleShowModalAll() {
        // const modalDataAll = {
        //   providerName: 'All',
        //   specialty: 'All',
        //   claimsTotal: this.overallDistribution.count,
        //   newLevel1: this.overallDistribution.newLevel1,
        //   newLevel2: this.overallDistribution.newLevel2,
        //   newLevel3: this.overallDistribution.newLevel3,
        //   newLevel4: this.overallDistribution.newLevel4,
        //   newLevel5: this.overallDistribution.newLevel5,
        //   total: this.overallDistribution.newTotal,
        //   level1: this.overallDistribution.establishedLevel1,
        //   level2: this.overallDistribution.establishedLevel2,
        //   level3: this.overallDistribution.establishedLevel3,
        //   level4: this.overallDistribution.establishedLevel4,
        //   level5: this.overallDistribution.establishedLevel5,
        //   total: this.overallDistribution.establishedTotal
        // }

        const modalDataAll = this.overallDistribution.proRiskAnalysisRecord
        modalDataAll.providerName = 'All'
        modalDataAll.specialty = 'All'

        await this.handleShowModal(modalDataAll)
      },
      async handleShowModal(provider) {
        this.modalData = provider
        this.showModal = true
        await this.$nextTick()
        // this.providerRecords = await this.LOAD_PROVIDER_RECORDS({ dateRange: this.GET_DATERANGE(), providerName: provider.providerName })
        // console.log(this.providerRecords)
        // const providerEmCodeDistribution = this.calculateEMCodeDistribution(provider.claims)
        const providerEmCodeDistribution = {
          newPatient: {
            level1: provider.newLevel1,
            level2: provider.newLevel2,
            level3: provider.newLevel3,
            level4: provider.newLevel4,
            level5: provider.newLevel5,
            total: provider.newTotal
          },
          establishedPatient: {
            level1: provider.establishedLevel1,
            level2: provider.establishedLevel2,
            level3: provider.establishedLevel3,
            level4: provider.establishedLevel4,
            level5: provider.establishedLevel5,
            total: provider.establishedTotal
          }
        }

        let medicareDistributionBySpecialty = { newPatient: [], establishedPatient: [] }
        const medicareSpecialty = mapping[provider.specialty] || 'General Practice'

        if (medicareSpecialty) {
          medicareDistributionBySpecialty = bySpecialty[medicareSpecialty]
          medicareDistributionBySpecialty.newPatient.label = medicareSpecialty
          medicareDistributionBySpecialty.establishedPatient.label = medicareSpecialty
        }

        this.createBellCurveChart(providerEmCodeDistribution.newPatient, medicareDistributionBySpecialty.newPatient, 'modalContainer1')
        this.createBellCurveChart(providerEmCodeDistribution.establishedPatient, medicareDistributionBySpecialty.establishedPatient, 'modalContainer2')
      },
      async generateRandomSample() {
        const shuffledArray = this.modalData.claims.slice()

        // Fisher-Yates shuffle algorithm
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
        }

        this.sampleForm.claims = shuffledArray.slice(0, this.sampleForm.size)
        await this.$nextTick()
        await this.renderSampledDistributions()
      },
      async importRecords() {
        const importList = this.sampleForm.claims.map(x => {
          // const year = x.dateOfService.slice(0, 4)
          // const month = x.dateOfService.slice(4, 6)
          // const day = x.dateOfService.slice(6, 8)
          // const isoDateOfService = `${year}-${month}-${day}`

          return {
            patientNumber: x.claimId,
            dateOfService: x.dateOfService,
            provider: x.providerName,
            projectId: this.sampleForm.projectId,
            dxs: x.dxCodes.map((y, i) => {
              return { originalDx: y.originalDx, sequence: i + 1 }
            }),
            cpts: x.cptCodes.map((y, i) => {
              let mods = []
              if (y.proRiskAnalysisModifiers.length) {
                mods = y.proRiskAnalysisModifiers.map((z, j) => {
                  return { originalModifier: z.originalModifier }
                })
              }
              return { originalCpt: y.originalCpt, sequence: i + 1, modifiers: mods }
            })
          }
        })

        await this.POST_PRO_TEMPLATE_MULTIPLE(importList)
        this.$message.info('Records Imported Successfully')
      },
      // resetFileInput() {
      //   const input = document.getElementById('importFileNew')
      //   input.value = null
      // },
      // async onFileChange(e) {
      //   const input = document.getElementById('importFileNew')
      //   this.inputFiles = input.files
      //   if (!this.inputFiles) {
      //     return
      //   }

      //   this.importFileNewName = this.inputFiles[0].name

      //   const text = await this.inputFiles[0].text()
      //   this.process(text)
      // },
      async renderTableCharts(onDemand) {
        onDemand = onDemand || this.activeTab
        if (onDemand === 'distributionByProvider' || onDemand === 'all') {
          this.localProviderDistribution.forEach((provider, index) => {
            let medicareDistributionBySpecialty = { newPatient: [], establishedPatient: [] }
            const medicareSpecialty = mapping[provider.specialty] || 'General Practice'

            if (medicareSpecialty) {
              medicareDistributionBySpecialty = bySpecialty[medicareSpecialty]
            }

            const newPatientEl = document.getElementById(`newPatientsByProvider${provider.providerName}`)

            if (newPatientEl) {
              const newPatient = {
                level1: provider.newLevel1,
                level2: provider.newLevel2,
                level3: provider.newLevel3,
                level4: provider.newLevel4,
                level5: provider.newLevel5,
                total: provider.newTotal
              }
              this.createMiniBellCurveChart(newPatient, medicareDistributionBySpecialty.newPatient, `newPatientsByProvider${provider.providerName}`)
            }

            const establishedPatientEl = document.getElementById(`establishedPatientsByProvider${provider.providerName}`)
            if (establishedPatientEl) {
              const establishedPatient = {
                level1: provider.establishedLevel1,
                level2: provider.establishedLevel2,
                level3: provider.establishedLevel3,
                level4: provider.establishedLevel4,
                level5: provider.establishedLevel5,
                total: provider.establishedTotal
              }
              this.createMiniBellCurveChart(establishedPatient, medicareDistributionBySpecialty.establishedPatient, `establishedPatientsByProvider${provider.providerName}`)
            }
          })
        }

        if (onDemand === 'distributionBySpecialty' || onDemand === 'all') {
          this.localSpecialtyDistribution.forEach((specialty, index) => {
            let medicareDistributionBySpecialty = { newPatient: [], establishedPatient: [] }
            const medicareSpecialty = mapping[specialty.specialty] || 'General Practice'

            if (medicareSpecialty) {
              medicareDistributionBySpecialty = bySpecialty[medicareSpecialty]
            }

            const newPatientEl = document.getElementById(`newPatientsBySpecialty${specialty.specialty}`)
            if (newPatientEl) {
              const newPatient = {
                level1: specialty.newLevel1,
                level2: specialty.newLevel2,
                level3: specialty.newLevel3,
                level4: specialty.newLevel4,
                level5: specialty.newLevel5,
                total: specialty.newTotal
              }
              this.createMiniBellCurveChart(newPatient, medicareDistributionBySpecialty.newPatient, `newPatientsBySpecialty${specialty.specialty}`)
            }

            const establishedPatientEl = document.getElementById(`establishedPatientsBySpecialty${specialty.specialty}`)
            if (establishedPatientEl) {
              const establishedPatient = {
                level1: specialty.establishedLevel1,
                level2: specialty.establishedLevel2,
                level3: specialty.establishedLevel3,
                level4: specialty.establishedLevel4,
                level5: specialty.establishedLevel5,
                total: specialty.establishedTotal
              }
              this.createMiniBellCurveChart(establishedPatient, medicareDistributionBySpecialty.establishedPatient, `establishedPatientsBySpecialty${specialty.specialty}`)
            }
          })
        }
      },
      // async renderCharts() {
      //   // const emCodeDistribution = this.calculateEMCodeDistribution(this.claims)

      //   // this.createBellCurveChart(emCodeDistribution.newPatient, medicare.newPatient, 'container1', 'New Patient EM Distribution')
      //   // this.createBellCurveChart(emCodeDistribution.establishedPatient, medicare.establishedPatient, 'container2', 'Established Patient EM Level Distribution')
      //   // await this.renderTableCharts(null)
      // },
      async process(text) {
        this.claims = processFile(text)
        this.providers = this.getProviders(this.claims)
        this.specialties = this.getSpecialties(this.claims)

        await this.$nextTick()
        await this.renderTableCharts(null)
      },
      async processClaimsImport() {
        const processedClaims = this.claims.map(x => {
          const year = x.dateOfService.slice(0, 4)
          const month = x.dateOfService.slice(4, 6)
          const day = x.dateOfService.slice(6, 8)
          const isoDateOfService = `${year}-${month}-${day}`

          return {
            claimId: x.claimId,
            dateOfService: isoDateOfService,
            providerName: x.providerName,
            providerSpecialty: x.providerSpecialty,
            providerTaxonomy: x.providerTaxonomy,
            emLevel: parseInt(x.emLevel),
            fileName: this.importFileNewName,
            proRiskAnalysisDxs: x.dxCodes.map((y, i) => {
              return { originalDx: y, lineSequence: i + 1 }
            }),
            proRiskAnalysisCpts: x.cptCodes.map((y, i) => {
              let mods = []
              if (y.modifiers.length) {
                mods = y.modifiers.map((z, j) => {
                  return { originalModifier: z, lineSequence: j + 1 }
                })
              }

              return { originalCpt: y.cpt, lineSequence: i + 1, proRiskAnalysisModifiers: mods, units: parseInt(y.units), segment: y.segment }
            })
          }
        })

        await this.POST_PRO_RISK_ANALYSIS(processedClaims)
      },
      handleCriteriaChange() {
        this.localdateRange = this.localdateRange || []
        this.SET_DATERANGE(this.localdateRange)
        this.$refs.distributionProvidersGrid.getData()
        this.$refs.distributionSpecialtiesGrid.getData()
        this.getOverallDistribution()
      },
      // getProviders() {
      //   const uniqueProviders = [...new Set(this.claims.map(claim => claim.providerName))]

      //   const providers = uniqueProviders.map(x => {
      //     return {
      //       providerName: x,
      //       providerSpecialty: this.claims.find(y => x === y.providerName).providerSpecialty,
      //       claims: this.claims.filter(y => x === y.providerName),
      //       providerEmCodeDistribution: this.calculateEMCodeDistribution(this.claims.filter(y => x === y.providerName))
      //     }
      //   })

      //   return providers
      // },
      // getSpecialties() {
      //   const uniqueSpecialties = [...new Set(this.claims.map(claim => claim.providerSpecialty))]

      //   const specialties = uniqueSpecialties.map(x => {
      //     return {
      //       providerSpecialty: x,
      //       claims: this.claims.filter(y => x === y.providerSpecialty),
      //       providerEmCodeDistribution: this.calculateEMCodeDistribution(this.claims.filter(y => x === y.providerSpecialty))
      //     }
      //   })

      //   return specialties
      // },
      calculateEMCodeDistribution(claims) {
        const distribution = {
          newPatient: { level1: 0, level2: 0, level3: 0, level4: 0, level5: 0, total: 0 },
          establishedPatient: { level1: 0, level2: 0, level3: 0, level4: 0, level5: 0, total: 0 }
        }

        const newPatientEmCodes = ['99202', '99203', '99204', '99205', '99242', '99243', '99244', '99245', '99252', '99253', '99254', '99255', '99341', '99342', '99344', '99345', '99374', '99375', '99377', '99378', '99379', '99380', '99381', '99382', '99383', '99384', '99385', '99386', '99387', '99460', '99461', '99462', '99463', '99464']
        const establishedPatientEmCodes = ['99211', '99212', '99213', '99214', '99215', '99242', '99243', '99244', '99245', '99252', '99253', '99254', '99255', '99347', '99348', '99349', '99350', '99391', '99392', '99393', '99394', '99395', '99396', '99397', '99421', '99422', '99423', '99439', '99441', '99442', '99443', '99487', '99489', '99490', '99491']

        claims.forEach(claim => {
          claim.cptCodesDisplay.split(',').forEach(cptCode => {
            const level = parseInt(cptCode.toString()[4], 10)
            if (newPatientEmCodes.some(x => x === cptCode)) {
              distribution.newPatient[`level${level}`]++
              distribution.newPatient.total++
            } else if (establishedPatientEmCodes.some(x => x === cptCode)) {
              distribution.establishedPatient[`level${level}`]++
              distribution.establishedPatient.total++
            }
          })
        })

        return distribution
      },
      // minMaxScale2DArrayWithOffset(arr, offset = 0.0) {
      //   // Extract the values to be scaled
      //   const values = arr.map(item => item[1])

      //   // Find the min and max values
      //   const minVal = Math.min(...values)
      //   const maxVal = Math.max(...values)

      //   // Function to scale a single value with offset
      //   const scaleValueWithOffset = (value, min, max, offset) => {
      //     const scaledValue = (value - min) / (max - min)
      //     return scaledValue * (1 - 2 * offset) + offset
      //   }

      //   // Scale the values in the array
      //   const scaledArray = arr.map(item => [item[0], scaleValueWithOffset(item[1], minVal, maxVal, offset)])

      //   return scaledArray
      // },
      percentageScale2DArray(arr) {
        // Extract the values to be scaled
        const values = arr.map(item => item[1])

        // Find the sum of all values
        const sum = values.reduce((acc, val) => acc + val, 0)

        // Scale each value to percentage
        const scaledArray = arr.map(item => [item[0], (item[1] / sum) * 100])

        return scaledArray
      },
      createBellCurveChart(distribution, comparisonDistribution, containerId, title) {
        const showMedicare = this.medicareCompare && !containerId.includes('sample')
        const levels = ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5']

        let data = levels.map(level => {
          return [level, distribution[level.toLowerCase().replace(' ', '')]]
        })

        let series = [
          {
            name: '',
            data: data,
            baseSeries: 1,
            marker: {
              radius: 2
            }
          }
        ]

        if (showMedicare) {
          let data2 = levels.map(level => {
            return [level, comparisonDistribution[level.toLowerCase().replace(' ', '')]]
          })
          data2 = this.percentageScale2DArray(data2)
          data = this.percentageScale2DArray(data)

          series = [
            {
              name: 'Your Claims',
              data: data,
              zIndex: 1,
              marker: {
                radius: 2
              },
              tooltip: {
                pointFormatter: function() {
                  return `Your Claims: ${this.y.toFixed(2)}%`
                }
              }
            },
            {
              name: comparisonDistribution.label ? `Medicare ${comparisonDistribution.label}` : 'Medicare',
              data: data2,
              fillOpacity: 0.2,
              color: 'lightgrey',
              marker: {
                radius: 3
              },
              tooltip: {
                pointFormatter: function() {
                  return `Medicare: ${this.y.toFixed(2)}%`
                }
              }
            }
          ]
        }

        this.highCharts.chart(containerId, {
          chart: {
            type: 'areaspline',
            height: 250
          },
          title: {
            text: title
          },
          legend: {
            enabled: showMedicare
          },
          yAxis: {
            title: {
              text: showMedicare ? 'Scaled as %' : 'Actual Values'
            }
          },
          xAxis: {
            labels: {
              formatter: function() {
                // Add 1 to the value to make it 1-indexed
                return this.value + 1
              }
            },
            tickPositions: [0, 1, 2, 3, 4]
          },
          series: series
        })
      },
      createMiniBellCurveChart(distribution, comparisonDistribution, containerId) {
        const levels = ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5']

        let data = levels.map(level => {
          return [level, distribution[level.toLowerCase().replace(' ', '')]]
        })

        let series = [
          {
            name: '',
            data: data,
            baseSeries: 1,
            marker: {
              radius: 1
            }
          }
        ]

        if (this.medicareCompare) {
          let data2 = levels.map(level => {
            return [level, comparisonDistribution[level.toLowerCase().replace(' ', '')]]
          })
          data2 = this.percentageScale2DArray(data2)
          data = this.percentageScale2DArray(data)

          series = [
            {
              name: '',
              data: data,
              zIndex: 1,
              marker: {
                radius: 1
              },
              tooltip: {
                pointFormatter: function() {
                  return `Your Claims: ${this.y.toFixed(2)}%`
                }
              }
            },
            {
              name: 'Medicare',
              data: data2,
              fillOpacity: 0.2,
              color: 'lightgrey',
              marker: {
                radius: 2
              },
              tooltip: {
                pointFormatter: function() {
                  return `Medicare: ${this.y.toFixed(2)}%`
                }
              }
            }
          ]
        }

        this.highCharts.chart(containerId, {
          chart: {
            type: 'areaspline',
            height: 90,
            backgroundColor: 'transparent'
          },
          title: {
            text: null
          },
          legend: {
            enabled: false
          },
          xAxis: {
            visible: false
          },
          yAxis: {
            visible: false
          },
          series: series
        })
      },
      async requestDistributionByProviderData(data) {
        try {
          data.dateRange = this.GET_DATERANGE()
          this.vueTables2Query = data
          const resultArray = await Promise.all([this.LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED(data)])
          this.localProviderDistribution = resultArray[0].data
          this.delayedRenderCharts('distributionByProvider')
          return resultArray[0]
        } catch (err) {
          console.log(err)
        }
      },
      async requestDistributionBySpecialtyData(data) {
        try {
          data.dateRange = this.GET_DATERANGE()
          this.vueTables2Query = data
          const resultArray = await Promise.all([this.LOAD_DISTRIBUTION_BY_SPECIALTY_LIST_PAGINATED(data)])
          this.localSpecialtyDistribution = resultArray[0].data
          this.delayedRenderCharts('distributionBySpecialty')
          return resultArray[0]
        } catch (err) {
          console.log(err)
        }
      },
      delayedRenderCharts(onDemand) {
        setTimeout(() => {
          this.renderTableCharts(onDemand)
        }, 100)
      },
      async getOverallDistribution() {
        try {
          this.overallDistribution = await this.LOAD_OVERALL_DISTRIBUTION({ dateRange: this.GET_DATERANGE() })
          this.renderOverallDistribution()
        } catch (err) {
          console.log(err)
        }
      },
      renderOverallDistribution() {
        const newPatient = {
          level1: this.overallDistribution.proRiskAnalysisRecord.newLevel1,
          level2: this.overallDistribution.proRiskAnalysisRecord.newLevel2,
          level3: this.overallDistribution.proRiskAnalysisRecord.newLevel3,
          level4: this.overallDistribution.proRiskAnalysisRecord.newLevel4,
          level5: this.overallDistribution.proRiskAnalysisRecord.newLevel5,
          total: this.overallDistribution.proRiskAnalysisRecord.newTotal
        }
        // const establishedPatient = {
        //   level1: this.overallDistribution.proRiskAnalysisRecord.establishedLevel1,
        //   level2: this.overallDistribution.proRiskAnalysisRecord.establishedLevel2,
        //   level3: this.overallDistribution.proRiskAnalysisRecord.establishedLevel3,
        //   level4: this.overallDistribution.proRiskAnalysisRecord.establishedLevel4,
        //   level5: this.overallDistribution.proRiskAnalysisRecord.establishedLevel5,
        //   total: this.overallDistribution.proRiskAnalysisRecord.establishedTotal
        // }
        this.createBellCurveChart(newPatient, medicare.newPatient, 'container1', 'New Patient EM Distribution')
        // this.createBellCurveChart(establishedPatient, medicare.establishedPatient, 'container2', 'Established Patient EM Level Distribution')
      },
      async requestProRiskRecordsData(data) {
        try {
          data.dateRange = this.GET_DATERANGE()
          data.providerName = this.modalData.groupBy === 'provider' ? this.modalData.providerName : null
          data.specialty = this.modalData.groupBy === 'specialty' ? this.modalData.specialty : null
          data.groupBy = this.modalData.groupBy
          data.selections = this.selectionData.map(m => m.recordId)
          this.vueTables2Query = data
          const resultArray = await Promise.all([this.LOAD_PRO_RISK_RECORD_LIST_PAGINATED(data)])
          this.localProRiskRecords = resultArray[0]
          resultArray[0].data = this.setProRiskRecordsPaginatedData(resultArray[0].data)
          return resultArray[0]
        } catch (err) {
          console.log(err)
        }
      },
      setProRiskRecordsPaginatedData(data) {
        const response = data.map(m => {
          if (this.selectionData.find(f => f.recordId === m.recordId)) {
            m.selected = true
          }
          return m
        })
        return response
      },
      deleteClaim(row) {
        const index = this.sampleForm.claims.findIndex(f => f.recordId === row.recordId)
        this.sampleForm.claims.splice(index, 1)
      },
      closeDistributionDialog(done) {
        this.selectionData = []
        this.sampleForm = {
          size: 10,
          projectId: null,
          project: {},
          claims: []
        }
        done()
      },
      async getProRiskRecordsDataRandom() {
        try {
          const payload = {
            dateRange: this.GET_DATERANGE(),
            providerName: this.modalData.groupBy === 'provider' ? this.modalData.providerName : null,
            specialty: this.modalData.groupBy === 'specialty' ? this.modalData.specialty : null,
            groupBy: this.modalData.groupBy,
            selections: [],
            sampleSize: this.sampleForm.size
          }
          const resultArray = await Promise.all([this.LOAD_PRO_RISK_RECORD_RANDOM_LIST(payload)])
          this.selectionData = resultArray[0]
          this.sampleForm.claims = resultArray[0]
          this.setProRiskRecordsPaginatedRandomData(resultArray[0])
          await this.$nextTick()
          await this.renderSampledDistributions()
        } catch (err) {
          console.log(err)
        }
      },
      setProRiskRecordsPaginatedRandomData(data) {
        data.forEach((v, i) => {
          const sourceIndex = this.$refs.proRiskRecordsGrid.data.findIndex(f => f.recordId === v.recordId)
          if (sourceIndex !== -1) {
            this.$refs.proRiskRecordsGrid.data[sourceIndex].selected = true
          }
        })
      },
      handleEmCategorySelect() {},
      handleProviderSpecialtiesSelect(isOpenEvent) {
        console.log(isOpenEvent)
      }
    },
    created: function() {
      this.localdateRange = this.newDateRange(-90, 30)
      this.SET_DATERANGE(this.localdateRange)
    },
    computed: {
      ...mapState('globals/', ['dateRangePickerConfig']),
      ...mapGetters('globals/', ['GET_DATERANGE']),
      ...mapState('dropdowns/', ['dropdowns'])
    },
    mounted: async function() {
      await import(/* webpackChunkName: "highcharts" */ 'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
      })

      await import(/* webpackChunkName: "highcharts" */ 'highcharts/modules/histogram-bellcurve').then(({ default: bellcurve }) => {
        bellcurve(this.highCharts)
      })

      await Promise.all([this.GET_DROPDOWNS(this.dropdownsNeeded)])

      // create placeholder overall distribution
      const placeholderNewPatient = { level1: 0, level2: 1, level3: 2, level4: 1, level5: 0 }
      // const placeholderEstablishedPatient = { level1: 0, level2: 1, level3: 2, level4: 1, level5: 0 }

      this.createBellCurveChart(placeholderNewPatient, [], 'tempContainer1', 'New Patient EM Distribution')
      // this.createBellCurveChart(placeholderEstablishedPatient, [], 'tempContainer2', 'Established Patient EM Level Distribution')
      // get overall distribution
      this.getOverallDistribution()
    }
  }
</script>

<style scoped>
  >>> .level-row {
    display: flex;
    width: 90%;
  }

  >>> .level-cell {
    flex: 1;
    padding: 6px;
  }

  >>> .level-row:last-child .level-cell {
    border-top: 1px solid #ddd;
    border-bottom: none;
  }

  table {
    table-layout: auto;
    border-collapse: collapse;
  }

  th {
    text-align: left;
  }

  td,
  th {
    padding: 0px 10px 0px 10px;
  }

  >>> .twentyPercent {
    width: 24%;
  }

  >>> .highcharts-credits {
    display: none;
  }

  .clickable {
    color: #337ab7;
    cursor: pointer;
    background-color: #e6eef4;
    padding: 2px;
    border-radius: 5px;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-left: 5px;
    text-align: center;
    border: 1px solid #ccddea;
    font-size: 14px;
  }

  .sectionHeader {
    padding-bottom: 5px;
    margin: 30px 0px 15px 0px;
    border-bottom: 1px solid #eee;
    font-weight: 400;
    line-height: 1;
    color: rgb(119, 119, 119);
  }

  >>> .filterIcon {
    float: left;
    font-size: 22px;
    padding-right: 0px;
    margin: 0px 5px 0px 5px;
  }
</style>
